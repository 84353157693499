<template>
  <div>
    <h1>Δαπάνες</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Expenses",

  data: () => ({}),

  computed: {
    ...mapGetters(["loading", "currencies"]),
  },

  methods: {},
};
</script>
